import { Link } from 'gatsby';
import React from 'react';

import Layout from '../components/layout/layout';
import { ProjectsImgs } from '../Projects';

const Realizacje = () => {
    return (
        <Layout
            title="Realizacje - WrapTown - profesjonalne oklejanie samochodów"
            desc="Sprawdź nasze realizacje z zakresu zmiany koloru auta, dechromingu czy ochrony lakieru. Przyjeżdżają do nas klienci z całej Wielkopolski."
            url="https://wraptown.pl/realizacje"
            type="website"
        >
            <main className="px-4 max-w-7xl mx-auto pt-20 pb-20 lg:px-6">
                <div className="mb-10">
                    <p>
                        <Link to="/">Home</Link> / Realizacje
                    </p>
                </div>
                <div className="mb-10">
                    <h1 className="text-4xl  mb-6 font-extrabold text-black xl:text-5xl leading-tight xl:leading-snug">
                        Realizacje - WrapTown
                    </h1>
                    <p>Sprawdź nasze zrealizowane projekty!</p>
                </div>

                <div className="grid grid-flow-row gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8">
                    {ProjectsImgs.reverse()
                    .map((project) => {
                        return (
                            <Link key={project.id} to={project.link}>
                                <div className="relative">
                                    <div className="absolute bottom-4 left-4 h-8 px-3 md:h-10 mr-4 md:px-5 text-white flex justify-center items-center bg-blue-700 transition-colors duration-150 border border-blue-700 rounded-lg focus:shadow-outline">
                                        <h2 className="font-bold">
                                            {project.cat}
                                        </h2>
                                    </div>
                                    <img src={project.href} alt={project.alt} />
                                </div>
                            </Link>
                        );
                    })}
                </div>
            </main>
        </Layout>
    );
};

export default Realizacje;
